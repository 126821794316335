import * as React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { Container } from 'react-bootstrap';

const IndexPage = () => (
  <Layout>
    <Seo title="Thank you!" />
    <Container className="singleCol text-center py-5">
      <h1><strong>Thank you!</strong></h1>
      <p>We will get back to you shortly. </p>
      <p><Link to="/">Click here</Link> to head back to our home page.</p>
    </Container>
  </Layout>
)

export default IndexPage
